import { cva, type VariantProps } from "./style.utils";

// -----------------------------------------------------------------------------

export type SheetOverlayVariants = VariantProps<typeof sheetOverlayVariants>;

export const sheetOverlayVariants = cva({
  base: [
    // layout
    "grid place-items-center overflow-y-auto",
    // positioning
    "fixed inset-0 z-50",
    // background
    "bg-overlay",
    // animation in
    "data-[state=open]:animate-in",
    "data-[state=open]:fade-in-0",
    // animation out
    "data-[state=closed]:animate-out",
    "data-[state=closed]:fade-out-0",
  ],
});

// -----------------------------------------------------------------------------

export type SheetContentVariants = VariantProps<typeof sheetContentVariants>;

export const sheetContentVariants = cva({
  base: [
    // layout
    "flex max-h-dvh flex-col overflow-y-auto",
    "group/sheet @container/sheet",
    // positioning
    "fixed z-50",
    // radii/shadows
    "shadow-lg",
    // borders
    "border-shade-15",
    // background
    "bg-white",
    // animation
    "transition ease-in-out",
    // animation in
    "data-[state=open]:animate-in",
    "data-[state=open]:duration-500",
    // animation out
    "data-[state=closed]:animate-out",
    "data-[state=closed]:duration-300",
  ],
  variants: {
    side: {
      top: [
        // position
        "inset-x-0 top-0",
        // borders
        "border-b",
        // animation in
        "data-[state=open]:slide-in-from-top",
        // animation out
        "data-[state=closed]:slide-out-to-top",
      ],
      bottom: [
        // position
        "inset-x-0 bottom-0",
        // borders
        "border-t",
        // animation in
        "data-[state=open]:slide-in-from-bottom",
        // animation out
        "data-[state=closed]:slide-out-to-bottom",
      ],
      left: [
        // layout
        "w-3/4 max-w-290px",
        // position
        "inset-y-0 left-0",
        // borders
        "border-r",
        // animation in
        "data-[state=open]:slide-in-from-left",
        // animation out
        "data-[state=closed]:slide-out-to-left",
      ],
      right: [
        // layout
        "w-3/4 max-w-290px",
        // position
        "inset-y-0 right-0",
        // borders
        "border-l",
        // animation in
        "data-[state=open]:slide-in-from-right",
        // animation out
        "data-[state=closed]:slide-out-to-right",
      ],
    },
  },
  compoundVariants: [],
  defaultVariants: {
    side: "left",
  },
});

// -----------------------------------------------------------------------------

export type SheetHeaderVariants = VariantProps<typeof sheetHeaderVariants>;

export const sheetHeaderVariants = cva({
  base: [
    // layout
    "grid min-h-65px items-center px-20px py-13px",
    // border
    "border-b border-shade-15",
  ],
});

// -----------------------------------------------------------------------------

export type SheetMainVariants = VariantProps<typeof sheetMainVariants>;

export const sheetMainVariants = cva({
  base: "flex-1",
});

// -----------------------------------------------------------------------------

export type SheetFooterVariants = VariantProps<typeof sheetFooterVariants>;

export const sheetFooterVariants = cva({
  base: "shrink-0",
});

// -----------------------------------------------------------------------------

export type SheetTitleVariants = VariantProps<typeof sheetTitleVariants>;

export const sheetTitleVariants = cva({
  base: "text-balance text-h5 font-normal leading-tight",
});

// -----------------------------------------------------------------------------

export type SheetDescriptionVariants = VariantProps<
  typeof sheetDescriptionVariants
>;

export const sheetDescriptionVariants = cva({
  base: "text-md font-semibold leading-tight text-shade-100",
});

// -----------------------------------------------------------------------------

export type SheetCloseIconButtonVariants = VariantProps<
  typeof sheetCloseIconButtonVariants
>;

export const sheetCloseIconButtonVariants = cva({
  base: [
    // layout
    "flex size-30px items-center justify-center",
    // positioning
    "absolute right-13px top-17px",
    // radii/shadows
    "rounded-full",
    // background
    "bg-shade-4",
    // foreground
    "text-shade-40",
    // animation
    "transition-colors",
    // focus/hover/active
    "hover:bg-shade-8 hover:text-shade-60",
    // focus unset outline
    "focus:outline-none",
    // focus reset outline (keyboard only)
    "focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-ring",
    // disabled
    "disabled:pointer-events-none",
  ],
});
